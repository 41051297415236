<svg xmlns="http://www.w3.org/2000/svg"
     class="board"
     [attr.viewBox]="LEFT + ' ' + UP + ' ' + WIDTH + ' ' + HEIGHT"
     preserveAspectRatio="xMidYMid meet">
    <g [attr.transform]="getBoardTransformation()">
        <g *ngFor="let coordAndContent of state.getCoordsAndContents()"
           id="click-{{ coordAndContent.coord.x }}-{{ coordAndContent.coord.y }}"
           (click)="onClick(coordAndContent.coord)"
           [attr.transform]="getPieceTranslation(coordAndContent.coord)">
            <!-- Background -->
            <rect id="square-{{ coordAndContent.coord.x }}-{{ coordAndContent.coord.y }}"
                  x="0"
                  y="0"
                  [attr.width]="SPACE_SIZE"
                  [attr.height]="SPACE_SIZE"
                  [ngClass]="getSquareClasses(coordAndContent.coord)"/>
            <!-- Pieces -->
            <g *ngIf="coordAndContent.content !== pieces.EMPTY" >
                <g *ngIf="coordAndContent.content === pieces.PAWN"
                   app-martian-chess-pawn
                   mainShapeId="pawn-{{ coordAndContent.coord.x }}-{{ coordAndContent.coord.y }}"
                   [pieceClasses]="getPieceClasses(coordAndContent.coord)"
                   [style]="style"/>
                <g *ngIf="coordAndContent.content === pieces.DRONE"
                   app-martian-chess-drone
                   [pieceClasses]="getPieceClasses(coordAndContent.coord)"
                   mainShapeId="drone-{{ coordAndContent.coord.x }}-{{ coordAndContent.coord.y }}"
                   [style]="style"/>
                <g *ngIf="coordAndContent.content === pieces.QUEEN"
                   app-martian-chess-queen
                   mainShapeId="queen-{{ coordAndContent.coord.x }}-{{ coordAndContent.coord.y }}"
                   [pieceClasses]="getPieceClasses(coordAndContent.coord)"
                   [style]="style"/>
            </g>
        </g>
        <g *ngIf="selectedPieceInfo.isPresent()">
            <rect *ngFor="let possibleLanding of selectedPieceInfo.get().legalLandings"
                    id="indicator-{{ possibleLanding.x }}-{{ possibleLanding.y }}"
                    [attr.transform]="getPieceTranslation(possibleLanding)"
                    [attr.x]="SPACE_SIZE/2 - INDICATOR_SIZE/2"
                    [attr.y]="SPACE_SIZE/2 - INDICATOR_SIZE/2"
                    [attr.width]="INDICATOR_SIZE"
                    [attr.height]="INDICATOR_SIZE"
                    (click)="onClick(possibleLanding)"
                    class="indicator"/>
        </g>
    </g>
    <g id="clock-or-count-down-view"
       (click)="onClockClick()"
       [attr.transform]="getTranslationAtXY(5.25, 0)">
        <rect x="0" y="0"
              class="transparent"
              [attr.width]="SPACE_SIZE"
              [attr.height]="SPACE_SIZE"/>
        <circle id="clock-or-count-down-circle"
                [attr.cx]="0.5 * SPACE_SIZE"
                [attr.cy]="0.5 * SPACE_SIZE"
                [attr.r]="0.4 * SPACE_SIZE"
                [ngClass]="getClockCircleClasses()"/>
        <text *ngIf="countDown.isPresent()"
              id="count-down-text"
              class="text-giant text-center"
              [ngClass]="'player' + getCurrentPlayer().getValue()"
              [attr.x]="0.5 * SPACE_SIZE"
              [attr.y]="0.5 * SPACE_SIZE"
              [ngClass]="getClockCircleClasses()">{{ countDown.get() }}</text>
        <polyline *ngIf="countDown.isAbsent()"
                  class="base"
                  [attr.points]="clockNeedlesPoints"
                  [ngClass]="getClockCircleClasses()"
                  stroke-linecap="round"/>
    </g>
    <g id="mode-cog-view"
       [attr.transform]="configViewTranslation">
        <g id="mode-cog"
           (click)="onModeCogClick()">
            <rect x="0" y="0"
                  class="transparent"
                  [attr.width]="SPACE_SIZE"
                  [attr.height]="SPACE_SIZE"/>
            <path [ngClass]="getPlayerClass(getCurrentPlayer())"
                  [attr.transform]="configCogTransformation"
                  d="M35.497,15.111h-1.656c-0.282-0.924-0.653-1.807-1.102-2.646l1.176-1.175c1.364-1.366,1.366-3.582,0-4.949l-1.258-1.258 c-0.656-0.656-1.548-1.025-2.476-1.025l0,0c-0.93,0-1.818,0.369-2.477,1.025l-1.176,1.175c-0.838-0.446-1.721-0.817-2.645-1.102 V3.501c0-1.934-1.566-3.5-3.5-3.5h-1.778c-1.934,0-3.5,1.566-3.5,3.5v1.655c-0.923,0.282-1.807,0.653-2.645,1.102l-1.174-1.175 c-1.367-1.367-3.584-1.367-4.951,0L5.081,6.34c-1.367,1.366-1.367,3.583,0,4.949l1.174,1.175c-0.446,0.84-0.818,1.723-1.102,2.646 H3.5c-1.934,0-3.5,1.566-3.5,3.5v1.778c0,1.932,1.566,3.5,3.5,3.5h1.654c0.283,0.922,0.654,1.807,1.102,2.645l-1.175,1.176 c-1.366,1.365-1.366,3.582-0.001,4.949l1.258,1.258c0.656,0.654,1.547,1.023,2.475,1.023h0.001c0.929,0,1.818-0.369,2.475-1.023 l1.176-1.176c0.837,0.447,1.722,0.818,2.644,1.102v1.656c0,1.932,1.566,3.5,3.5,3.5h1.778c1.934,0,3.5-1.568,3.5-3.5v-1.656 c0.924-0.283,1.807-0.654,2.645-1.102l1.177,1.176c0.655,0.656,1.547,1.023,2.476,1.023c0.93,0,1.817-0.369,2.477-1.023 l1.256-1.258c1.366-1.367,1.365-3.582,0-4.949l-1.176-1.174c0.447-0.84,0.818-1.725,1.102-2.646h1.656c1.934,0,3.5-1.568,3.5-3.5 v-1.777C38.997,16.679,37.43,15.111,35.497,15.111z M19.499,27.499c-4.41,0-8-3.588-8-8c0-4.411,3.59-8,8-8c4.412,0,8,3.589,8,8 C27.499,23.911,23.911,27.499,19.499,27.499z"/>
            </g>
        <g *ngIf="displayModePanel"
           id="mode-panel">
            <rect class="base"
                  x="0"
                  [attr.y]="(- listOfStyles.length * SPACE_SIZE)"
                  [attr.width]="1 * SPACE_SIZE"
                  [attr.height]="listOfStyles.length * SPACE_SIZE"
                  stroke-width="8"/>
            <ng-container *ngFor="let proposedStyle of listOfStyles; index as y;">
                <g [id]="proposedStyle.name"
                   app-martian-chess-queen
                   (click)="chooseStyle(y)"
                   [attr.transform]="getTranslationAtXY(0, - y - 1)"
                   [pieceClasses]="['player' + getCurrentPlayer().getValue() + '-fill']"
                   [style]="proposedStyle.style"/>
            </ng-container>
        </g>
    </g>
    <g id="captures">
        <g *ngFor="let player of Player.PLAYERS">
            <g id="capture-of-player-{{ player.toString() }}"
               [attr.transform]="getCapturesTransformation(player)">
                <g *ngFor="let value of state.getCapturesOf(player); index as piece">
                    <g *ngIf="value > 0"
                       [attr.transform]="getTranslationAtXY(0, 2 * piece)">
                        <text *ngIf="value > 1"
                              class="text-giant text-center"
                              [ngClass]="getPlayerClass(player)"
                              [attr.x]="SPACE_SIZE / 2"
                              [attr.y]="SPACE_SIZE / 2"
                        >{{ value + ' x ' }}</text>
                        <g id="piece-{{ piece }}-player-{{ player.toString() }}"
                           [attr.transform]="getTranslationAtXY(1, 0)">
                            <g *ngIf="piece === 0"
                               app-martian-chess-pawn
                               [pieceClasses]="[getPlayerClass(player)]"
                               [style]="style"/>
                            <g *ngIf="piece === 1"
                               app-martian-chess-drone
                               [pieceClasses]="[getPlayerClass(player)]"
                               [style]="style"/>
                            <g *ngIf="piece === 2"
                               app-martian-chess-queen
                               [pieceClasses]="[getPlayerClass(player)]"
                               [style]="style"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
