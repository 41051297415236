<div class="columns is-vcentered is-align-items-stretch">
    <div class="column is-one-third">
        <div id="infos"
             class="box is-fullheight">
            <progress class="progress is-primary" [attr.value]="successfulSteps" [attr.max]="getNumberOfSteps()"></progress>
            <div class="content is-medium">
                <p class="title">{{ getCurrentStepTitle() }} [{{stepIndex + 1}}/{{ getNumberOfSteps() }}]</p>
                <p id='currentMessage' [innerHTML]="currentMessage"></p>
            </div>
            <div *ngIf="currentReason.isPresent()" class="message is-danger" role="alert">
                <div class="message-header" i18n>Failure</div>
                <div class="message-body" id="currentReason" [innerHTML]="currentReason.get()"></div>
            </div>
            <div class="block">
                <button id="retryButton"
                        class="button mr-3"
                        *ngIf="tutorialOver === false && ((stepFinished && stepFinished[stepIndex]) || moveAttemptMade)"
                        (click)="retry()"
                        i18n
                >Try again</button>
                <button id="nextButton"
                        class="button is-primary mr-3 mb-1"
                        *ngIf="tutorialOver === false"
                        (click)="next()"
                >
                    <ng-container *ngIf="steps[stepIndex] && steps[stepIndex].isInformation() else notInfo" i18n>Ok</ng-container>
                    <ng-template #notInfo>
                        <ng-container *ngIf="stepFinished && stepFinished[stepIndex] else notFinished" i18n>Continue</ng-container>
                    </ng-template>
                    <ng-template #notFinished>
                        <ng-container i18n>Skip</ng-container>
                    </ng-template>
                </button>
                <button id="restartButton"
                        class="button"
                        *ngIf="tutorialOver"
                        (click)="start()"
                        i18n
                >Try again</button>
                <button id="playLocallyButton"
                        class="button"
                        *ngIf="tutorialOver"
                        (click)="playLocally()"
                        i18n
                >Play locally</button>
                <button id="playOnlineButton"
                        class="button"
                        *ngIf="tutorialOver"
                        (click)="createGame()"
                        i18n
                >Create an online game</button>
                <button id="showSolutionButton"
                        class="button"
                        *ngIf="moveAttemptMade && stepFinished[stepIndex] === false"
                        (click)="showSolution()"
                        i18n
                >See solution</button>
                <div class="select">
                    <select id="steps" (change)="changeStep($event)">
                        <ng-container *ngFor="let step of steps; index as i">
                            <ng-container *ngIf="i === stepIndex else otherstep">
                                <option id="step_{{ i }}" [value]="i" selected>{{ step.title }}</option>
                            </ng-container>
                            <ng-template #otherstep>
                                <option [value]="i">
                                    <ng-container *ngIf="stepFinished[i] else notdone">v </ng-container>
                                    <ng-template #notdone>x </ng-template>
                                    <span id="step_{{ i }}">{{ step.title }}</span>
                                </option>
                            </ng-template>
                        </ng-container>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="column">
        <div class="box is-fullheight p-5"
             [ngClass]="getBoardHighlight()">
            <div id="board" class="box is-fullheight">
                <div #board></div>
            </div>
        </div>
    </div>
</div>
