<svg xmlns="http://www.w3.org/2000/svg"
     class="board"
     [attr.viewBox]="getViewBox().toSVGString()"
     preserveAspectRatio="xMidYMid meet">
    <g *ngFor="let coordAndContent of getState().getCoordsAndContents()">
        <g *ngIf="isReachable(coordAndContent.content)"
           id="click-{{ coordAndContent.coord.x }}-{{ coordAndContent.coord.y }}"
           (click)="onClick(coordAndContent.coord)"
           [attr.transform]="getHexaCenterTranslationAt(coordAndContent.coord)">
            <polygon id="space-{{ coordAndContent.coord.x }}-{{ coordAndContent.coord.y }}"
                     class="base"
                     [attr.points]="getHexaPoints()"
                     stroke-linecap="round"
            />
            <circle *ngIf="coordAndContent.content.isPlayer()"
                    id="piece-{{ coordAndContent.coord.x }}-{{ coordAndContent.coord.y }}"
                    cx="0"
                    cy="0"
                    [attr.r]="SPACE_SIZE * 0.5"
                    [ngClass]="getSquareClassesAt(coordAndContent.coord)"
                    class="base"
            />
        </g>
    </g>
    <g *ngFor="let dropped of droppedCoords">
        <circle [attr.transform]="getHexaCenterTranslationAt(dropped)"
                (click)="onClick(dropped)"
                id="dropped-{{ dropped.x }}-{{ dropped.y }}"
                cx="0"
                cy="0"
                [attr.r]="SPACE_SIZE * 0.5"
                [ngClass]="['moved-stroke', getPlayerClass(getCurrentPlayer())]"
                class="base"
        />
    </g>
</svg>
