<svg xmlns="http://www.w3.org/2000/svg"
     class="board"
     [attr.viewBox]="getViewBox().toSVGString()"
     preserveAspectRatio="xMidYMid meet">
    <g #remainingPieces
       *ngFor="let player of Player.PLAYERS">
        <g *ngFor="let piece of getRemainingPiecesTypeOfPlayer(player); index as pieceIdX"
           id="remaining-piece-{{ piece.toString() }}"
           (click)="onPieceClick(piece)"
           [attr.transform]="getRemainingPieceTranslate(player, pieceIdX)">
            <circle [attr.r]="getPieceRadius(piece)"
                    [attr.cx]="0"
                    [attr.cy]="0"
                    [ngClass]="getSidePieceClasses(piece)"
                    [attr.stroke-width]="pieceStrokeWidth"
                    class="no-fill"
                    pointer-events="fill"
            />
            <text id="player-zero-tiles-count"
                  class="backgrounded-text text-big"
                  dominant-baseline="middle"
                  dy="0.1em"
                  text-anchor="middle"
                  [attr.transform]="getRemainingPieceQuantityTransform(piece, pieceIdX)"
            >{{ getRemainingPieceQuantity(piece) }}</text>
        </g>
    </g>
    <g #boardDiv>
        <g *ngFor="let line of board; index as y">
            <g *ngFor="let spaceContent of line; index as x">
                <rect id="click-{{ x }}-{{ y }}"
                      (click)="onBoardClick(x, y)"
                      [attr.x]="SPACE_SIZE * x"
                      [attr.y]="SPACE_SIZE * y"
                      [attr.width]="SPACE_SIZE"
                      [attr.height]="SPACE_SIZE"
                      [ngClass]="getRectClasses(x, y)"
                      class="base"
                />
                <circle *ngFor="let piece of getListPieces(spaceContent)"
                        (click)="onBoardClick(x, y)"
                        [attr.r]="getPieceRadius(piece)"
                        [attr.transform]="getPieceTranslate(x, y)"
                        [ngClass]="getPieceClasses(piece)"
                        [attr.stroke-width]="pieceStrokeWidth"
                        class='no-fill'
                />
            </g>
        </g>
        <rect *ngIf="chosenCoord.isPresent()"
              id="chosen-{{ chosenCoord.get().x }}-{{ chosenCoord.get().y }}"
              (click)="onBoardClick(chosenCoord.get().x, chosenCoord.get().y)"
              [attr.x]="SPACE_SIZE * chosenCoord.get().x"
              [attr.y]="SPACE_SIZE * chosenCoord.get().y"
              [attr.width]="SPACE_SIZE"
              [attr.height]="SPACE_SIZE"
              class="base no-fill selected-stroke"
              pointer-events="fill"
        />
        <rect *ngFor="let coord of victoryCoords"
              id="victory-{{ coord.x }}-{{ coord.y }}"
              class="base no-fill victory-stroke mid-stroke"
              [attr.x]="SPACE_SIZE * coord.x"
              [attr.y]="SPACE_SIZE * coord.y"
              [attr.width]="SPACE_SIZE"
              [attr.height]="SPACE_SIZE"
        />
    </g>
</svg>
