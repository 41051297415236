<div *ngIf="isCustomisable()">
    <div class="field columns is-gapless mb-0">
        <div class="field-label column">
        <label class="label has-text-left" i18n>Configuration</label>
        </div>
        <div class="field-body column has-text-right">
        <div class="control">
            <div class="select is-primary is-fullwidth">
                <select id="ruleSelect"
                        [disabled]="editable === false"
                        (change)="onChange($event)">
                    <option id="config-dropdown-{{ rulesConfigDescription.getDefaultConfig().name() }}"
                            [value]="rulesConfigDescription.getDefaultConfig().name()"
                            [selected]="isSelectedConfig(rulesConfigDescription.getDefaultConfig().name())">
                        {{ rulesConfigDescription.getDefaultConfig().name() }}
                    </option>
                    <option *ngFor="let standardConfig of rulesConfigDescription.getNonDefaultStandardConfigs()"
                            id="config-dropdown-{{ standardConfig.name() }}"
                            [value]="standardConfig.name()"
                            [selected]="isSelectedConfig(standardConfig.name())">
                        {{ standardConfig.name() }}
                    </option>
                    <option value="Custom"
                            id="config-dropdown-custom"
                            [selected]="isSelectedConfig('Custom')"
                            i18n>Custom</option>
                </select>
            </div>
        </div>
        </div>
    </div>

    <form [formGroup]="rulesConfigForm"
          class="mt-2"
          id="{{ getChosenConfigName() }}_values">
        <fieldset class="left-border" >
            <div *ngFor="let configParameter of getFields()"
                 id="{{ configParameter }}_config"
                 class="mb-2">
                <div class="field is-horizontal columns is-gapless">
                    <div class="field-label column is-three-quarters">
                        <label class="label has-text-left">{{ rulesConfigDescription.getI18nName(configParameter) }}</label>
                    </div>
                    <div class="field-body column has-text-right">
                        <div class="field is-narrow">
                            <div class="control">
                                <label *ngIf="isBoolean(configParameter) else notBoolean"
                                       class="checkbox">
                                    <input id="{{ configParameter }}_boolean_config_input"
                                           [formControlName]="configParameter"
                                           type="checkbox"
                                           class="checkbox"
                                           required />
                                </label>
                                <ng-template #notBoolean>
                                    <div *ngIf="isNumber(configParameter)"
                                         class="control">
                                        <input id="{{ configParameter }}_number_config_input"
                                               [formControlName]="configParameter"
                                               type="number"
                                               class="input is-small"
                                               [ngClass]="isValid(configParameter) === false ? 'is-danger' : ''"
                                               required
                                               placeholder="{{ rulesConfigDescription.getI18nName(configParameter) }}" />
                                    </div>
                                    <div *ngIf="isValid(configParameter) === false"
                                         id="{{configParameter}}-error"
                                         class="help is-danger"
                                         role="alert">{{ getErrorMessage(configParameter) }}</div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
</div>
