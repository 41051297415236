<svg xmlns="http://www.w3.org/2000/svg"
     class="board"
     [attr.viewBox]="getViewBox().toSVGString()"
     preserveAspectRatio="xMidYMid meet"
>
    <g *ngFor="let line of board; index as y">
        <g *ngFor="let currentSquare of line; index as x"
           id="click-{{ x }}-{{ y }}"
           (click)="onClick(x, y)">
            <rect class="base"
                  [attr.x]="SPACE_SIZE * x"
                  [attr.y]="SPACE_SIZE * y"
                  [attr.width]="SPACE_SIZE"
                  [attr.height]="SPACE_SIZE"
            />
            <circle *ngIf="currentSquare !== EMPTY"
                    class="base"
                    [attr.cx]="(SPACE_SIZE * x) + (SPACE_SIZE / 2)"
                    [attr.cy]="(SPACE_SIZE * y) + (SPACE_SIZE / 2)"
                    [attr.r]="(SPACE_SIZE / 2) - STROKE_WIDTH"
                    [ngClass]="getSquareFillClass(x, y)"
                    pointer-events="fill"
            />
        </g>
    </g>
    <rect *ngIf="last.isPresent()"
          class="base no-fill last-move-stroke mid-stroke"
          [attr.x]="SPACE_SIZE * last.get().x"
          [attr.y]="SPACE_SIZE * last.get().y"
          [attr.width]="SPACE_SIZE"
          [attr.height]="SPACE_SIZE"
    />
    <rect *ngFor="let coord of victoryCoords"
          id="victory-coord-{{ coord.x }}-{{ coord.y }}"
          class="base no-fill victory-stroke mid-stroke"
          [attr.x]="SPACE_SIZE * coord.x"
          [attr.y]="SPACE_SIZE * coord.y"
          [attr.width]="SPACE_SIZE"
          [attr.height]="SPACE_SIZE"
    />
</svg>
