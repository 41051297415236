<svg xmlns="http://www.w3.org/2000/svg"
     class="board"
     [attr.viewBox]="getViewBox().toSVGString()"
     preserveAspectRatio="xMidYMid meet">

    <g app-blank-goban
       [width]="getState().getWidth()"
       [height]="getState().getHeight()"
       (clickCallBack)="onClick($event)">
    </g>
    <g id="piece-ko-territory-markers-and-dead-stones">
        <g *ngFor="let coordAndContent of getState().getCoordsAndContents()">
            <g *ngIf="spaceIsFull(coordAndContent.coord) else empty">
                <circle [attr.cx]="(SPACE_SIZE * 0.5) + (SPACE_SIZE * coordAndContent.coord.x)"
                        [attr.cy]="(SPACE_SIZE * 0.5) + (SPACE_SIZE * coordAndContent.coord.y)"
                        [attr.r]="SPACE_SIZE * 0.44"
                        (click)="onClick(coordAndContent.coord)"
                        [ngClass]="getSpaceClass(coordAndContent.coord)"
                        class="base"
                />
                <circle *ngIf="isLastSpace(coordAndContent.coord)"
                        [attr.cx]="(SPACE_SIZE * 0.5) + (SPACE_SIZE * coordAndContent.coord.x)"
                        [attr.cy]="(SPACE_SIZE * 0.5) + (SPACE_SIZE * coordAndContent.coord.y)"
                        [attr.r]="SPACE_SIZE * 0.44"
                        class="base no-fill last-move-stroke"
                />
                <line *ngIf="isDead(coordAndContent.coord)"
                      (click)="onClick(coordAndContent.coord)"
                      [attr.x1]="SPACE_SIZE * coordAndContent.coord.x"
                      [attr.y1]="SPACE_SIZE * coordAndContent.coord.y"
                      [attr.x2]="SPACE_SIZE + (SPACE_SIZE * coordAndContent.coord.x)"
                      [attr.y2]="SPACE_SIZE + (SPACE_SIZE * coordAndContent.coord.y)"
                      class="base no-fill captured-stroke"
                />
            </g>
            <ng-template #empty>
                <circle *ngIf="isTerritory(coordAndContent.coord)"
                        [attr.cx]="(SPACE_SIZE * 0.5) + (SPACE_SIZE * coordAndContent.coord.x)"
                        [attr.cy]="(SPACE_SIZE * 0.5) + (SPACE_SIZE * coordAndContent.coord.y)"
                        [attr.r]="SPACE_SIZE * 0.2"
                        [attr.class]="coordAndContent.content === GoPiece.LIGHT_TERRITORY ? 'player1-fill' : 'player0-fill'"
                        (click)="onClick(coordAndContent.coord)"
                />
            </ng-template>
        </g>
    </g>
    <rect *ngIf="ko.isPresent()"
          id="ko-{{ ko.get().x }}-{{ ko.get().y }}"
          [attr.transform]="getTranslationAt(ko.get())"
          [attr.width]="SPACE_SIZE * 0.5"
          [attr.height]="SPACE_SIZE * 0.5"
          [attr.x]="SPACE_SIZE * 0.25"
          [attr.y]="SPACE_SIZE * 0.25"
          class="captured-stroke mid-stroke no-fill"
    />
    <circle *ngFor="let capture of captures"
            (click)="onClick(capture)"
            [attr.transform]="getTranslationAt(capture)"
            [attr.cx]="SPACE_SIZE * 0.5"
            [attr.cy]="SPACE_SIZE * 0.5"
            [attr.r]="SPACE_SIZE * 0.14"
            class="captured-fill"
    />
</svg>
